<template>
	<div>
		<div class="container darkBack">
			<div class="whiteFont font20">数据分析</div>
			<!-- 数据区块 -->
			<div class="instructor">
				<div class="instructorItem borderBack">
					<div class="font13 lightBlueFont">{{num.title}}</div>
					<div class="font21 blueFont">
						<countTo :startVal='0' :endVal='num.num'  :decimals="0" :duration='3000'></countTo>
					</div>
				</div>
				<div class="instructorItem borderBack">
					<div class="font13 lightBlueFont">{{accounted.title}}</div>
					<div class="font21 blueFont">
						<countTo :startVal='0' :endVal='accounted.num'  :decimals="2" :duration='3000'></countTo>%
					</div>
				</div>
			</div>
			<!-- 下方两图表 -->
			<div class="chartGroup">
				<!-- 左侧级别比例 -->
				<div class="chartItem">
					<div class="font18 blueFont">● 级别比例</div>
					<div id="level"></div>
				</div>
				<!-- 右侧男女比例 -->
				<div>
					<div class="chartItem">
						<div class="font18 blueFont">● 男女比例</div>
						<div class="sex">
							<div class="imgGroup">
								<img class="manPic" src="../../../assets/images/man1.png" >
								<img class="womanPic" src="../../../assets/images/woman1.png" >
							</div>
							<div class="legendText">
								<span>男</span>
								<span>女</span>
							</div>
							<div class="proportion">
								<div class="man">{{sex.man}}</div>
								<div class="woman">{{sex.woman}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {getAjax, timestamp} from "/src/assets/js/websocket";

	// 引入数字滚动组件
import countTo from 'vue-count-to';
  export default {
    components: { countTo },
		data() {
			return{
				num:{title:"社会体育指导员人数",num:0},
				accounted:{title:"社会体育指导员履职率",num:0},
				
				sex:{
					man:"53%",
					woman:"47%"
				},
				rank:{
					data:[
						{
							"value": 26,
							"name": "三级"
						},{
							"value": 20,
							"name": "二级"
						},{
							"value": 10,
							"name": "一级"
						}
					]
				}
			}
		},
		methods:{
			// 绘制级别比例图
			drawLevelProportion(number){
				// let myChart = this.$echarts.init(document.getElementById("level"));
				let myChart = this.$echarts.getInstanceByDom(document.getElementById("level"))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById("level"));
				}
				
				let series = [];
				let pieDatas = number.data
				let maxRadius =  50,
				    barWidth = 5,
				    barGap =  5;
				let sumValue = 0;
				let showValue = true,showPercent = true;
				pieDatas.map(item => {
				    sumValue += item.value;
				})
				let barColor =  [
				        {"color1": "#E6AF08",},
				        {"color1": "#19AF6D",},
				        {"color1": "#0E6DE9",},
				        {"color1": "#99259a",},
				    ];
				pieDatas.map((item, i) => {
				    series.push({
				        type: 'pie',
				        hoverAnimation: false, //鼠标移入变大
				        radius: [(maxRadius - i * (barGap + barWidth)) + '%', (maxRadius - (i + 1) * barWidth - i * barGap) + '%'],
				        center: [ "25%", "50%"],
				        label: {show: false},
				        itemStyle: {
				            label: {show: false,},
				            labelLine: {show: false},
				            borderWidth: 5,
				        },
				        data: [{
				            value: item.value,
				            name: item.name,
				            itemStyle: {
				                color: barColor[i].color1
				            }
				        }, {
				            value: sumValue - item.value,
				            name: '',
				            itemStyle: {
				                color: "transparent",
				            },
				            hoverAnimation: false
				        }]
				    })
				    series.push({
				        name: 'blank',
				        type: 'pie',
				        silent: true,
				        z: 0,
				        hoverAnimation: false, //鼠标移入变大
				        radius: [(maxRadius - i * (barGap + barWidth)) + '%', (maxRadius - (i + 1) * barWidth - i * barGap) + '%'],
				        center: [ "25%", "50%"],
				        label: {
				            show: false
				        },
				        itemStyle: {
				            label: {show: false,},
				            labelLine: {show: false},
				            borderWidth: 5,
				        },
				        data: [{
				            value: 1,
				            itemStyle: {
				                color: "#0A0F5F",
				                borderWidth: 0
				            },
				            hoverAnimation: false
				        }]
				    });
				})
				let option = {
				    legend: {
				        show: true,
				        left: '50%',
				        top: 'middle',
				        itemWidth: 10,
				        itemHeight: 10,
				        itemGap:  10,
				        textStyle: {
				            fontSize:  '0.12rem',
				            color:  '#A8DAEB',
				        },
				        formatter: (name) => {
							var datas = pieDatas;
							let total = 0;
							datas.map(item => {
								total += (item.value - 0)
							})
							let valueIndex = datas.map(item => item.name).indexOf(name);
							let num = ((datas[valueIndex].value / total) * 100).toFixed(2)
							if(isNaN(num)) num = 0;
							return name + "  " + (showPercent ? num + "%" : '');
						} ,
				    },
				    series: series,
				};
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				});
			},
			// 获取指导员数据
			getInstructor(){
				let that = this;
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp(),
				}
				getAjax({
					url: "/map/screen/getHomeSocialSportsInstructor",
					method:"GET",
					data:data,
					success:function(res){
						// console.log(res)
						// 获取左上角数据
						that.num.num = res.data.data_list.total;
						that.accounted.num = res.data.resumption_lv;
						// 级别比例
						that.rank.data = [];
						res.data.project_level.forEach((item,index)=>{
							that.rank.data.push({
								value:item.proportion,
								name:item.title
							})
						})
						that.drawLevelProportion(that.rank);
						// 男女比例
						that.sex.man = res.data.men_women_proportion[0].proportion+"%";
						that.sex.woman = res.data.men_women_proportion[1].proportion+"%";
					},
				})
			}
		},
		mounted() {
			let that = this;
			that.getInstructor();
			// that.drawLevelProportion(this.rank);
		}
	}
</script>

<style scoped>
	.font13{font-size: 0.13rem}
	.font21{font-size: 0.21rem}
	.container{
		width: 5.94rem;
		height: 4.19rem;
		padding: 0.2rem;
		position: relative;
	}
	.instructor{
		display: flex;
		justify-content: space-around;
		margin-top: 0.2rem;
	}
	.instructorItem{
		width: 2.44rem;
		height: 0.93rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.chartGroup{
		margin-top: 0.2rem;
		display: flex;
	}
	.chartItem{
		width: 2.96rem;
	}
	#level{
		width: 2.70rem;
		height: 2.40rem;
	}
	.sex{
		width: 2.4rem;
		margin-top: 0.4rem;
		margin-left: 0.2rem;
	}
	.imgGroup{
		display: flex;
		justify-content: space-between;
	}
	.manPic{
		width: 0.46rem;
		height: 0.54rem;
	}
	.womanPic{
		width: 0.51rem;
		height: 0.55rem;
	}
	.legendText{
		display: flex;
		justify-content: space-between;
		color: #7B8CB7;
		margin-top: 0.2rem;
	}
	.proportion{
		width: 2.4rem;
		height: 0.32rem;
		background: url(../../../assets/images/sexBorder.png) no-repeat;
		background-size: 100% 100%;
		margin-top: 0.1rem;
		display: flex;
		align-items: center;
		padding: 0.2rem 0.1rem 0.2rem 0.1rem;
		box-sizing:border-box;
	}
	.man , .woman{
		height: 0.28rem;
		line-height: 0.28rem;
		color: white;
		text-align: center;
	}
	.man{
		width: 1.5rem;
		background-color: #0C68A1;
	}
	.woman{
		width: 1.5rem;
		background-color: #C77828;
	}
</style>